<template>
  <div class="container">
    <div class="row">
      <div class="container">
        <div style="max-width:18em; margin: 0 auto;">
          <a href="/" class="edshedlogo">
            <img src="/img/logo-edshed.png" alt="Logo" class="img-responsive center-block">
          </a>
        </div>
        <!-- <div class="center-block" style="width: fit-content; text-align: center">
            <img src="/img/logo.png" class="inline-block sublogo">
            <img src="/img/math-shed-logo-dark.png" class="inline-block sublogo mathslogo" v-if="$route.query.locale == 'en_US'">
            <img src="/img/maths-shed-logo-dark.png" class="inline-block sublogo mathslogo" v-else>
          </div> -->
        <!-- <img src="/img/Bee.svg" alt="Logo" class="img-responsive center-block logo"> -->
        <div class="section text-center content">
          <div class="box">
            <h1>You are lost.</h1>
            <h4>This page doesn't exist.</h4>
            <router-link class="button is-link" to="/">
              Home
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NotFound'
}
</script>

<style scoped>
  body {
    background-image: url('/public/img/skyBackground.jpg');
    background-size: cover;
    background-repeat:no-repeat;
    background-color: #b4e3f7;
    background-color: #f8f8f8;
  }
</style>
